import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Component, Inject} from '@angular/core';

import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-comparaison-images',
  templateUrl: './comparaison-images.component.html',
  styleUrls: ['./comparaison-images.component.scss']
})
export class DialogCompararaisonImageComponent {
  poteaux: any[] = [];

  constructor(
    private db: AngularFirestore,
    private afStorage: AngularFireStorage,
    public dialogRef: MatDialogRef<DialogCompararaisonImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.db.collection('projets').doc(data.projet.id).collection('poteaux').valueChanges().subscribe((datas) => {
      this.poteaux = [];
      for (const p of datas) {
        firebase.storage().ref(this.data.projet.libelle + '/images/' + p.name).listAll().then((res) => {

          for (const i of res.items) {
            i.getDownloadURL().then((l) => {
              console.log(l);
              // window.open(l, "_blank");
            });
          }

          this.poteaux.push({
            name: p.name,
            telephone: Object.values(p.images).length,
            serveur: res.items.length,
            ok: res.items.length >= Object.values(p.images).length,
            dateAdded: p.dateAdded
          });
        });
      }
    });
  }

  displayResult() {
    return this.poteaux.filter(x => !x.ok).length;
  }

  fermer(): void {
    this.dialogRef.close();
  }
}
